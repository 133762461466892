<template>
  <div :class="['three-item', tabName !== 'complete3' && tabName !== 'personBatch' ? '' : 'batch']">
    <!-- 头像 -->
    <small-user-card
      :size="38"
      :data="{
        userid: data.userId || '',
        avatar: data.userInfoOutput.avatar || '',
        username: data.userInfoOutput.userName || '',
        position: data.userInfoOutput.jobName || ''
      }"
      @user-card="u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })"
    >
      <div slot="right" @click.stop>
        <div v-if="tabName !== 'complete3' && tabName !== 'personBatch'">
          <div @click="$emit('follow-click', data)" v-if="userId != data.userId" :class="['follow-btn ', data.isAttention == 1 ? 'unfollow' : 'follow']">
            <i class="el-icon-plus"></i>
            <span v-html="data.isAttention == 1 ? '取消关注' : '关注'"></span>
          </div>
        </div>
        <div v-else>
          <el-tag size="medium" type="danger" v-if="data.isTimeout">审阅超时</el-tag>
        </div>
      </div>
    </small-user-card>
    <!-- 三清文案 -->
    <div ref="three-item-content-ref" :style="{ height: data.showHeight + 'px' }" class="three-item__content">
      <!--今日工作成果-->
      <three-item-html v-if="todayResults.isShow" :data="todayResults" schema="content" />
      <!--明日工作计划-->
      <three-item-html v-if="tomorrow.isShow" :data="tomorrow" schema="list" />
      <!--经验分享-->
      <three-item-html v-if="experienceSharing.isShow" :data="experienceSharing" schema="content" />
      <!--未完成工作及原因-->
      <three-item-html v-if="undoneWork.isShow" :data="undoneWork" schema="content" />
      <!--主要问题和改进措施-->
      <three-item-html v-if="improvement.isShow" :data="improvement" schema="content" />
      <!--创新提案-->
      <three-item-html v-if="innovationProposal.isShow" :data="innovationProposal" schema="content" />
      <!--自我评价-->
      <three-item-html v-if="selfEvaluation.isShow" :data="selfEvaluation" schema="list" calssName="self-evaluation" />
      <!--保障措施-->
      <three-item-html v-if="safeguards.isShow" :data="safeguards" schema="content" />
      <!--需要赋能-->
      <three-item-html v-if="needPower.isShow" :data="needPower" schema="content" />
      <!--自定义事项-->
      <template v-for="(item, index) in customMatter">
        <three-item-html :key="index" v-if="item.isShow" :data="item" schema="list" />
      </template>
    </div>
    <p v-if="isShowExpand" class="expand-pack-btn" @click="handleExpand">
      {{ isExpand ? '收起' : '全文' }}
    </p>
    <div class="three-all" v-if="tabName !== 'complete3' && tabName !== 'personBatch'">
      <div class="argue-wrap">
        <p class="day">{{ data.submitTimeStr }}</p>
        <p class="action">
          <span class="argue support" @click="$emit('click-praise', data, data.isPraise === 1 ? 0 : 1)">
            <i :class="['iconfont', data.isPraise === 1 ? 'iconxingzhuang' : 'iconxingzhuang1']"></i>
            {{ data.isPraise == 1 ? '已赞' : '赞' }}</span
          >
          <span class="argue line">|</span>
          <span @click="$emit('argue-discuss', data, (data.commentType = 1))" class="argue discuss"><i class="iconfont icondiscuss"></i>评论</span>
          <span class="argue line" v-if="data.approveStatus != 0">|</span>
          <el-button
            class="readButton"
            v-if="data.approveStatus != 0"
            @click="$emit('check-approve', data, (data.commentType = 2))"
            :type="data.approveStatus == 101 ? 'danger' : data.approveStatus == 5 ? 'primary' : 'warning'"
            plain
          >
            <span v-if="data.approveStatus == 1">审阅</span>
            <span v-else-if="data.approveStatus == 5">撤销审阅</span>
            <span v-if="data.approveStatus == 101">审阅超时</span>
          </el-button>
        </p>
      </div>
      <div class="write-wrap" v-show="data.isArgue">
        <el-input
          resize="none"
          v-filteremoji
          class="write-input"
          type="textarea"
          :autosize="{ minRows: 1.5, maxRows: 9 }"
          :placeholder="data.commentType == 1 ? '请输入评论内容' : '请输入批注内容'"
          maxlength="800"
          show-word-limit
          v-model="data.comment"
        >
        </el-input>
        <!-- release(data) 根据后台配置项是否禁用-->
        <el-button style="height:38px;" v-if="data.commentType == 1" :disabled="!data.comment.trim()" @click="$emit('submit-discuss', data)" type="primary" size="small">发布</el-button>
        <!-- <el-button class="statusButton" type="danger" size="small" plain v-if="data.commentType == 2" @click="$emit('approve', data, 4)">审阅不通过</el-button>
        <el-button class="statusButton" type="success" size="small" plain v-if="data.commentType == 2" @click="$emit('approve', data, 3)">审阅通过</el-button> -->
      </div>
      <!-- 点赞和评论显示区域 -->
      <div v-if="commentShow.isShowPraise || commentShow.isShowComment" class="argue-info">
        <p v-if="commentShow.isShowPraise" class="like-person">
          <i class="iconfont iconxingzhuang1"></i>
          <span v-for="(p, i) in data.praiseOutputList" :key="i">
            <span class="pariseUsername" @click="routerInInfoData(p.userId)">{{ p.userName }}</span>
            <span class="comma">，</span>
          </span>
        </p>
        <el-divider v-if="commentShow.isShowAll"></el-divider>
        <div v-if="commentShow.isShowComment">
          <div class="like-p" v-for="(co, idx) in data.commentOutputList" :key="idx">
            <div v-if="!co.isDelete">
              <p class="like-name">
                <span class="name" @click="routerInInfoData(co.userId)">{{ co.userName }}</span>
                <span v-if="co.commentType == 1" style="margin-right: 4px;">:</span>
                <el-tag class="elTag" v-if="co.commentType == 1" :type="co.approveStatus == 4 ? 'danger' : co.approveStatus == 3 ? 'success' : ''" size="mini">{{
                  dict.reviewState[co.approveStatus]
                }}</el-tag>
                <span v-if="!!co.forUserId" style="padding:0px 4px;">回复</span>
                <span class="name" v-if="!!co.forUserId" @click="routerInInfoData(co.forUserId)">{{ co.forUserName }}</span>
                <span v-if="co.commentType != 1" style="margin-right: 4px;">:</span>
                <span v-html="co.content"></span>
                <em class="shift" v-show="co.userId == userId && data.userId == userId && co.commentType != 1" @click="$emit('shift-comment', co)">删除</em>
              </p>
              <p class="like-time">
                {{ co.createTimeVari }}
                <em class="shift" v-if="co.userId != userId" @click="$emit('reply-comment', data, co.id, (data.commentType = 1))">回复</em>
              </p>
            </div>
            <div v-else>
              <p class="like-time">{{ co.userName }} 撤回了审阅</p>
              <p class="like-time">{{ co.updateTimeVari }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="three-all batch-textarea" v-else>
      <p class="day">{{ data.submitTimeVari }}</p>
      <p class="postil">审阅批注</p>
      <!-- {{ data.deductionApprove }} -->
      <el-form v-if="isTojoy && data.deductionApprove" :model="batchRuleForm" label-position="left" :rules="rules" ref="batchRuleForm" class="demo-ruleForm">
        <el-form-item :show-message="false" prop="pointsName" class="select-content">
          <!-- @change="changeSelect" -->
          <el-select v-model="data.deductionApprove.pointsId" placeholder="请选择" class="selectBox">
            <el-option v-for="item in codeOfconducdList" :key="item.id" :label="item.label" :value="item.id">
              <el-popover placement="right" trigger="hover" :title="item.label"
                ><span slot="reference">{{ item.label }}</span>
              </el-popover>
            </el-option>
          </el-select>
          <span slot="label" class="dialog-footer">
            行为规范月度自评
            <el-button style="margin-left:10px" type="primary" size="mini" @click="lookButton">查看</el-button>
          </span>
        </el-form-item>
      </el-form>
      <el-input class="areaInput" resize="none" v-filteremoji type="textarea" placeholder="请输入批注内容" maxlength="800" v-model="data.remarks"> </el-input>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SmallUserCard from '@/components/democracy/small-user-card.vue'
import ThreeItemHtml from './three-item-html.jsx'
export default {
  name: 'three-item',
  components: {
    SmallUserCard,
    ThreeItemHtml
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    formConfig: {
      type: Object,
      default: () => {}
    },
    tabName: {
      type: String,
      default: () => {}
    },
    noComment: {
      type: Boolean,
      defaule: false
    },
    yesComment: {
      type: Boolean,
      defaule: false
    },
    codeOfconducdList: {
      type: Array
    }
  },
  data() {
    return {
      isShowExpand: false, // 是否显示 全文-收起 可点击块
      defaultHeight: 245, // 默认高度，如果超出
      isExpand: false, // 是否展开- true-收起  false-全文
      existingHeight: 0, // 存在的高度
      batchRuleForm: {
        score: '',
        pointsId: '',
        pointsName: '审核通过（不扣分）',
        comment: '',
        title: ''
      },
      rules: {
        pointsName: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      dict: {
        reviewState: {
          1: '待审阅',
          // 2: '审阅中',
          3: '审阅通过',
          4: '审阅不通过'
        }
      },
      isTojoy: JSON.parse(localStorage.getItem('isTojoy'))
    }
  },
  computed: {
    ...mapState({
      userName: state => state.userInfo.userName,
      userId: state => state.userInfo.userId
    }),
    // 今日成果
    todayResults({ formConfig, data }) {
      return {
        isShow: data?.workContent,
        // formConfig['type' + data.reportType].workContent.isEnabled === 1 && data.workContent,
        title: formConfig['type' + data.reportType]?.workContent?.showName,
        content: data?.workContent ?? '',
        reportStatus: data?.reportStatus ?? '',
        dueDate: data?.dueDate ?? '',
        reportType: data?.reportType ?? ''
      }
    },
    // 明日计划
    tomorrow({ formConfig, data }) {
      return {
        isShow:
          // formConfig['type' + data.reportType].workPlan.isEnabled === 1 &&
          data?.workPlan?.noUrgentImport || data?.workPlan?.noUrgentNoImport || data?.workPlan?.urgentImport || data?.workPlan?.urgentNoImport,
        title: formConfig['type' + data.reportType]?.workPlan?.showName,
        content: [
          {
            isShow: data?.workPlan?.urgentImport ?? false,
            tagName: '紧急重要',
            text: data?.workPlan?.urgentImport ?? ''
          },
          {
            isShow: data?.workPlan?.urgentNoImport ?? false,
            tagName: '紧急不重要',
            text: data?.workPlan?.urgentNoImport ?? ''
          },
          {
            isShow: data?.workPlan?.noUrgentImport ?? false,
            tagName: '不紧急重要',
            text: data?.workPlan?.noUrgentImport ?? ''
          },
          {
            isShow: data?.workPlan?.noUrgentNoImport ?? false,
            tagName: '不紧急不重要',
            text: data?.workPlan?.noUrgentNoImport ?? ''
          }
        ]
      }
    },
    // 经验分享
    experienceSharing({ formConfig, data }) {
      return {
        isShow:
          // formConfig['type' + data.reportType].experienceShare.isEnabled === 1 &&
          data?.experienceShare,
        title: formConfig['type' + data.reportType]?.experienceShare?.showName,
        content: data?.experienceShare
      }
    },
    // 未完成工作及原因
    undoneWork({ formConfig, data }) {
      return {
        isShow:
          // formConfig['type' + data.reportType].undoWork.isEnabled === 1 &&
          data?.undoWork,
        title: formConfig['type' + data.reportType]?.undoWork?.showName,
        content: data?.undoWork
      }
    },
    // 主要问题和改进措施
    improvement({ formConfig, data }) {
      return {
        isShow:
          // formConfig['type' + data.reportType].problem.isEnabled === 1 &&
          data?.problem,
        title: formConfig['type' + data.reportType]?.problem?.showName,
        content: data?.problem
      }
    },
    // 创新提案
    innovationProposal({ formConfig, data }) {
      return {
        isShow:
          // formConfig['type' + data.reportType].newIdea.isEnabled === 1 &&
          data?.newIdea,
        title: formConfig['type' + data.reportType]?.newIdea?.showName,
        content: data?.newIdea
      }
    },
    // 自我评价
    selfEvaluation({ formConfig, data }) {
      return {
        isShow: data?.selfPeakState >= 10 && data?.selfFullState >= 10 && data?.selfValueState >= 10,
        // formConfig['type' + data.reportType].selfPeakState.isEnabled === 1 &&
        // formConfig['type' + data.reportType].selfFullState.isEnabled === 1 &&
        // formConfig['type' + data.reportType].selfValueState.isEnabled === 1,
        title: '自我评价',
        content: [
          {
            isShow: true,
            text: `${formConfig['type' + data.reportType]?.selfPeakState?.showName} ${data?.selfPeakState}分 `
          },
          {
            isShow: true,
            text: `${formConfig['type' + data.reportType]?.selfFullState?.showName} ${data?.selfFullState}分`
          },
          {
            isShow: true,
            text: `${formConfig['type' + data.reportType]?.selfValueState?.showName} ${data?.selfValueState}分`
          }
        ] // 巅峰状态工作 满负荷工作 每刻的工作都最有价值
      }
    },
    // 保障措施
    safeguards({ formConfig, data }) {
      return {
        isShow:
          // formConfig['type' + data.reportType].safeguards.isEnabled === 1 &&
          data?.safeguards,
        title: formConfig['type' + data.reportType]?.safeguards?.showName,
        content: data?.safeguards
      }
    },
    // 需要赋能
    needPower({ formConfig, data }) {
      return {
        isShow:
          // formConfig['type' + data.reportType].needPower.isEnabled === 1 &&
          data?.needPower,
        title: formConfig['type' + data.reportType]?.needPower?.showName,
        content: data?.needPower
      }
    },
    // 自定义事项
    customMatter({ formConfig, data }) {
      let _data = []
      for (let index = 0; index < data?.selfOutputList?.length; index++) {
        const _self = data?.selfOutputList[index]
        _data.push({
          isShow:
            // formConfig['type' + data.reportType].isSelf.isEnabled === 1 &&
            data?.selfOutputList?.length,
          title: '自定义事项',
          content: [
            {
              isShow: !!_self?.itemContent,
              tagName: '事项',
              text: _self?.itemContent ?? ''
            },
            {
              isShow: !!_self?.itemResult,
              tagName: '完成结果',
              text: _self?.itemResult ?? ''
            },
            {
              isShow: !!_self?.remarks,
              tagName: '备注',
              text: _self?.remarks ?? ''
            }
          ]
        })
      }
      return _data
    },
    // 评论区域是否显示
    commentShow({ data }) {
      return {
        isShowAll: data?.commentOutputList?.length && data?.praiseOutputList?.length,
        isShowComment: data?.commentOutputList?.length,
        isShowPraise: data?.praiseOutputList?.length
      }
    }
  },
  watch: {
    data: {
      deep: true,
      async handler() {
        await this.$nextTick(() => {})
        if (this.isShowExpand && this.isExpand) {
          //俩false
          this.data.showHeight = this.existingHeight
        } else if (this.isShowExpand && !this.isExpand) {
          this.data.showHeight = this.defaultHeight
        } else {
          this.data.showHeight = this.existingHeight
        }
      }
    }
  },
  mounted() {
    // console.log('itemcard', this.data)
    this.$nextTick(() => {
      this.existingHeight = this.$refs['three-item-content-ref'].offsetHeight
      this.isShowExpand = this.existingHeight > this.defaultHeight + 50
      this.data.showHeight = this.isShowExpand ? this.defaultHeight : this.existingHeight
    })
  },
  methods: {
    handleExpand() {
      this.isExpand = !this.isExpand
      this.data.showHeight = this.isExpand ? this.existingHeight : this.defaultHeight
    },
    // 点击姓名跳转到个人主页
    routerInInfoData(data) {
      // this.$router.push({ name: 'homepage-navigation', query: { userId: data } })
      if (this.$route.path === '/homepage/navigation') {
        return false
      } else {
        this.$router.push({ name: 'homepage-navigation', query: { userId: data } })
      }
    },
    // 查看行为规范月度自评
    lookButton() {
      this.$emit('openDialog', this.data)
    }
  }
}
</script>

<style lang="scss">
.three-item {
  flex: 1;
  padding-bottom: 15px;
  .small-user-card {
    margin-bottom: 13px;
    .follow-btn {
      cursor: pointer;
      padding: 6px 10px;
      font-size: 12px;
      font-weight: 400;
      border-radius: 2px;
      color: $grey;
      line-height: 12px;
      text-align: center;
      &.unfollow {
        background: $f7f7f7;
        color: $grey;
      }
      &.follow {
        background: #ebf2ff;
        color: #3d7eff;
        .el-icon-plus {
          padding-right: 2px;
        }
      }
    }
  }
  &__content {
    margin-left: 48px;
    overflow: hidden;
    transition: 0.3s;
    &--textwrap {
      pre {
        white-space: pre-wrap;
        width: 100%;
        text-align: justify;
        overflow: auto;
        word-wrap: break-word;
      }
      .textwrap {
        &-title {
          font-size: 15px;
          font-weight: bold;
          color: $lightblack;
          line-height: 15px;
          margin-bottom: 10px;
        }
        &-text {
          font-size: 14px;
          font-weight: 400;
          color: $darkgrey;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }
      .tip-block {
        .tagname {
          display: inline-block;
          background: $f5f5f5;
          border-radius: 2px;
          padding: 5px 6px;
          font-size: 12px;
          font-weight: 400;
          color: $grey;
          line-height: 12px;
          margin-bottom: 10px;
        }
        .textwrap-text {
          margin-bottom: 15px;
        }
      }
      &.self-evaluation {
        .text {
          .tip-block {
            .textwrap-text {
              margin-bottom: 8px;
            }
            &:last-of-type {
              .textwrap-text {
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
  .expand-pack-btn {
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    color: $blue;
    line-height: 15px;
    margin-left: 48px;
    padding-top: 10px;
    margin-bottom: 15px;
  }
  .three-all {
    .argue-wrap {
      margin-left: 48px;
      display: flex;
      justify-content: space-between;
      .day {
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: $grey;
        line-height: 12px;
        margin-right: 5px;
      }
      .action {
        display: flex;
        // height: 13px;
        line-height: 34px;
        .argue {
          // float: right;

          // margin-right: 20px;
          // height: 12px;
          font-size: 12px;
          font-weight: 500;
          color: $darkgrey;
          cursor: pointer;
          // &:first-of-type {
          //   margin-right: 0;
          // }
          .iconfont {
            font-size: 12px;
            padding-right: 6px;
          }
          .iconxingzhuang {
            color: #ff526a;
          }
          &.support:hover {
            color: $blue;
          }
          &.discuss:hover {
            color: $blue;
          }
          &.line {
            color: #d8d8d8;
            margin: 0 16px;
          }
        }
        .readButton {
          width: 96px;
          // height: 34px;
          font-size: 14px;
          text-align: center;
          border-radius: 3px;
          border: none;
        }
      }
    }
    .write-wrap {
      margin-left: 48px;
      display: flex;
      padding-top: 15px;
      .write-input {
        margin-right: 10px;
      }
      .statusButton {
        height: 38px;
        border: none;
      }
    }
    .argue-info {
      margin-left: 48px;
      background: $f7f7f7;
      border-radius: 4px;
      margin-top: 15px;
      .el-divider {
        background: $linecolor;
        margin: 0;
      }
      .pariseUsername {
        cursor: pointer;
      }
      .comma {
        color: black;
      }
      .iconxingzhuang1 {
        padding-right: 6px;
        font-size: 13px;
      }
      .like-person {
        font-size: 13px;
        font-weight: 500;
        color: $blue;
        line-height: 22px;
        margin-left: 11px;
        padding: 2px 0;
        span:last-child {
          .comma {
            display: none;
          }
        }
      }
      .like-p {
        padding: 4px 0 4px 7px;
        font-size: 13px;
      }
      .like-time {
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: $grey;
        line-height: 12px;
        margin: 6px 0;
      }
      .like-name {
        .name {
          font-weight: 500;
          color: $blue;
          margin-right: 4px;
          cursor: pointer;
        }
        .elTag {
          border: none;
        }
      }
      .shift {
        cursor: pointer;
        color: $blue;
        margin-left: 10px;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
  .batch-textarea {
    margin-left: 48px;
    .postil {
      margin: 10px 0;
    }
    .day {
      height: 12px;
      font-size: 12px;
      font-weight: 400;
      color: $grey;
      line-height: 12px;
      margin-right: 5px;
    }
    .demo-ruleForm {
      .select-content {
        margin-bottom: 0;
        padding-bottom: 14px;
        border-bottom: solid 1px #eeeeee;
        .el-form-item__content {
          float: right;
          .selectBox {
            display: block;
            min-width: 400px;
            .el-input--suffix {
              .el-input__inner {
                border: none;
                text-align: right;
                padding-right: 40px;
              }
            }
          }
        }
      }
    }
    .areaInput {
      .el-textarea__inner {
        border: none;
        border-bottom: solid 1px #eee;
        color: #222222;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        padding: 0;
        margin-top: 10px;
      }
    }
  }
}
.batch {
  border: solid 1px #dddddd;
  padding: 15px;
}
.el-popover {
  .el-popover__title {
    height: 10px;
    // line-height: 10px;
    margin-bottom: 0;
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__list {
      .el-select-dropdown__item {
        display: block;
        width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
