const threeItemHtml = {
  name: 'three-item-html',

  functional: true,

  props: {
    data: {
      type: Object,
      default: () => {}
    },
    schema: {
      type: String,
      default: 'content' // list
    },
    calssName: {
      type: String,
      default: ''
    }
  },

  render: (h, { props, parent, data:$data }) => {
    const { data, schema, calssName, reportStatus, dueDate } = props
    let makeUpTitle = '';
    if(data.reportStatus === 4){
      if(data.reportType === 1) {
        console.log(data.dueDate)
        makeUpTitle = '补' + Number(new Date(data.dueDate).getMonth() + 1) + '月' + new Date(data.dueDate).getDate() + '日日清';
      } else if(data.reportType === 2) {
        makeUpTitle = '补' + Number(new Date(data.dueDate).getMonth() + 1) + '月' + new Date(data.dueDate).getDate() + '日周清';
      } else {
        makeUpTitle = '补' + Number(new Date(data.dueDate).getMonth() + 1) + '月月清';
      }
    }
    const _className = `three-item__content--textwrap ${calssName}`
    return (
      <div class={_className}>
        <h3 class='textwrap-title'>{data.reportStatus === 4 ? makeUpTitle : data.title}</h3>
        {schema === 'content' && (
          <p class='textwrap-text'>
            <pre>{data.content}</pre>
          </p>
        )}
        {schema === 'list' && (
          <div class='text'>
            {data.content
              .filter(c => c.isShow)
              .map((item, index) => {
                return item.isShow ? (
                  <div class='tip-block'>
                    {item.tagName ? <h4 class='tagname'>{item.tagName}</h4> : ''}
                    <p class='textwrap-text'>
                      <pre>{item.text}</pre>
                    </p>
                  </div>
                ) : (
                  ''
                )
              })}
          </div>
        )}
      </div>
    )
  }
}
export default threeItemHtml
